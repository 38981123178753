<script setup lang="ts">
const emits = defineEmits<{
  'arrow-clicked': [value: 'prev' | 'next']
}>()

const props = defineProps<{
  direction: 'prev' | 'next'
}>()

const arrowClicked = () => {
  emits('arrow-clicked', props.direction)
}

const iconName = computed(() => {
  if (props.direction === 'prev') return 'arrowLeft'
  else return 'arrowRight'
})
</script>

<template>
  <div
    :class="`carousel-arrow__wrap swiper--${direction}`"
    @click.stop.prevent="arrowClicked()"
  >
    <div class="carousel-arrow shadow-md">
      <BaseIcon :name="iconName" />
    </div>
  </div>
</template>

<style scoped>
.carousel-arrow__wrap {
  transform: translate(0, -50%);
  @apply outline-none absolute top-1/2 cursor-pointer z-10;
}

/* disable event click in all elements inside the clicked element */
.carousel-arrow__wrap > div,
.carousel-arrow__wrap > span {
  @apply pointer-events-none;
}

.swiper--prev {
  left: 0.5rem;
}

@screen md {
  .swiper--prev {
    left: 1rem;
  }
}

@screen lg {
  .swiper--prev {
    left: 1.5rem;
  }
}

.swiper--next {
  right: 0.5rem;
}

@screen md {
  .swiper--next {
    right: 1rem;
  }
}

@screen lg {
  .swiper--next {
    right: 1.5rem;
  }
}

.carousel-arrow {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  box-shadow: 0 2px 36px 0 rgba(#202020, 0.1);
  outline: none;
  @apply bg-white border border-gray-700 border-opacity-10 text-gray-700 relative flex items-center justify-center;
}

.carousel-arrow:hover,
.carousel-arrow:focus {
  @apply text-gray-700;
}

.carousel-arrow .icn:before {
  transform: translate(-50%, -50%);
  @apply absolute top-1/2 left-1/2;
}
</style>
