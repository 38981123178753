<template>
  <div class="swiper-pagination pagination-v1" />
</template>

<style>
.swiper-pagination.pagination-v1 {
  @apply bottom-4 flex items-center justify-center;
}

.swiper-pagination.pagination-v1 .swiper-pagination-bullet {
  @apply w-1 h-1 bg-white opacity-80;
}

.swiper-pagination.pagination-v1 .swiper-pagination-bullet-active {
  @apply w-2 h-2 opacity-100;
}
</style>
